<template>
  <div class="contactUs">
    <!-- 菜单 -->
    <my-nav id="myNav" pageName="contactUs"></my-nav>
    <div class="banner-box">
      <div class="banner-img"></div>
    </div>
    <div class="map-box">
      <img src="../../assets/images/map.png" class="map-img">
      <div class="map-msg clearfix">
        <div class="map-msg-logo">
          <img src="../../assets/images/logoCU.png" alt="">
          <div class="company">Guangzhou Supply Chain Management Co. LTD</div>
        </div>
        <div class="map-contact">
          <div class="footer-concat-item">
            <div class="footer-concat-icon">
              <i class="iconfont icon-dianhua"></i>
            </div>
            <div class="footer-concat-text">
              <div class="footer-concat-type">The phone</div>
              <div class="footer-concat-msg">+86 134 5047 3308</div>
            </div>
          </div>
          <div class="footer-concat-item">
            <div class="footer-concat-icon">
              <i class="iconfont icon-youjian-"></i>
            </div>
            <div class="footer-concat-text">
              <div class="footer-concat-type">Email</div>
              <div class="footer-concat-msg">ann.wu@apware.cn</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <my-footer id="footer"></my-footer>
  </div>
</template>

<script>
import MyNav from "../../components/nav/nav";
import MyFooter from "../../components/footer/footer";
export default {
  name: "contactUs",
  components: {
    MyNav,
    MyFooter,
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.contactUs {
  .banner-box {
    background-image: linear-gradient(-90deg, #3E4C5C, #1C1F22);
    .banner-img {
      width: 100%;
      height: 500px;
      background: url("../../assets/images/bannerContactUs.png") no-repeat center;
      background-size: auto 500px;
    }
  }
  .map-box {
    width: 100%;
    background: #171717;
    position: relative;
    padding-top: 342px;
    .map-img {
      width: 1200px;
      height: 466px;
      position: absolute;
      left: 50%;
      top: -190px;
      transform: translateX(-50%);
    }
    .map-msg {
      width: 1200px;
      height: auto;
      margin: 0 auto;
      .map-msg-logo {
        width: 750px;
        height: auto;
        float: left;
        img {
          width: 240px;
          height: 60px;
          display: block;
          margin: 0 auto;
        }
        .company {
          width: 100%;
          line-height: 20px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          padding: 30px 0 116px;
        }
      }
      .map-contact {
        width: auto;
        height: auto;
        margin-left: 750px;
        .footer-concat-item {
          width: 100%;
          height: auto;
          color: #fff;
          padding: 10px 0;
          .footer-concat-icon {
            width: 33px;
            height: 33px;
            float: left;
            .iconfont {
              font-size: 33px;
            }
          }
          .footer-concat-text {
            width: auto;
            margin-left: 44px;
            .footer-concat-type {
              width: 100%;
              line-height: 16px;
              font-size: 16px;
              margin-bottom: 9px;
            }
            .footer-concat-msg {
              width: 100%;
              line-height: 18px;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>