<template>
  <div class="nav-box">
    <div class="wrap clearfix">
      <img @click="gotoPage('/home')" src="../../assets/images/bbmart-logo.png" class="web-logo" />
      <div @click="gotoPage(item.path)" v-for="item in navList" :key="item.name" :class="{ 'nav-item': true, active: item.name === pageName }">{{ item.name }}</div>
      <div @click="gotoPage('/contactUs')" class="nav-concat">Contact us</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    pageName: {
      type: String,
      default: "Home",
    },
  },
  data() {
    return {
      navList: [
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Product",
          path: "/product",
        },
        {
          name: "About us",
          path: "/aboutUs",
        },
        {
          name: "News"
        },
      ],
    };
  },
  methods: {
    /** 跳转页面 */
    gotoPage(path) {
      if(path) {
        this.$router.push({
          path
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-box {
  width: 100%;
  height: auto;
  color: #fff;
  font-size: 20px;
  background: #171717;
  .wrap {
    width: 1200px;
    margin: 0 auto;
  }
  .web-logo {
    width: 240px;
    height: 60px;
    margin-top: 20px;
    float: left;
    cursor: pointer;
  }
  .nav-item {
    width: 205px;
    line-height: 100px;
    text-align: center;
    float: left;
    position: relative;
    cursor: pointer;
    &.active {
      color: #c02d31;
      &::after {
        content: "";
        width: 30px;
        height: 2px;
        background: #c02d31;
        position: absolute;
        left: 50%;
        margin-left: -15px;
        bottom: 23px;
      }
    }
  }
  .nav-concat {
    width: 140px;
    line-height: 100px;
    text-align: center;
    background: #cb2024;
    float: left;
    cursor: pointer;
  }
}
</style>
