<template>
  <div class="footer-box">
    <div class="wrap">
      <!-- 导航 -->
      <div class="footer-nav clearfix">
        <div @click="gotoPage(item.path)" v-for="item in navList" :key="item.label" class="footer-nav-item">
          <div class="text" v-if="item.type === 'text'">{{ item.label }}</div>
          <img v-else src="../../assets/images/bbmart-logo.png" />
        </div>
      </div>
      <!-- 联系方式 -->
      <div class="footer-concat clearfix">
        <div class="footer-concat-item">
          <div class="footer-concat-icon">
            <i class="iconfont icon-dianhua"></i>
          </div>
          <div class="footer-concat-text">
            <div class="footer-concat-type">The phone</div>
            <div class="footer-concat-msg">+86 134 5047 3308</div>
          </div>
        </div>
        <div class="footer-concat-item">
          <div class="footer-concat-icon">
            <i class="iconfont icon-youjian-"></i>
          </div>
          <div class="footer-concat-text">
            <div class="footer-concat-type">Email</div>
            <div class="footer-concat-msg">ann.wu@apware.cn</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <div class="footer-copyright">版权所有</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      navList: [
        {
          type: "text",
          label: "Home",
          path: '/home'
        },
        {
          type: "text",
          label: "Product",
          path: '/product'
        },
        {
          type: "pic",
          path: '/home'
        },
        {
          type: "text",
          label: "About us",
          path: '/aboutUs'
        },
        {
          type: "text",
          label: "News",
        },
        {
          type: "text",
          label: "Contact us",
          path: '/contactUs'
        },
      ],
    };
  },
  methods: {
    /** 跳转页面 */
    gotoPage(path) {
      if(path) {
        this.$router.push({
          path
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  height: auto;
  background: #1f1f1f;
  padding-top: 72px;
  .wrap {
    width: 1200px;
    margin: 0 auto;
  }
  .footer-nav {
    width: 100%;
    line-height: 49px;
    font-size: 20px;
    color: #fff;
    .footer-nav-item {
      width: 16.666%;
      line-height: 20px;
      text-align: center;
      float: left;
      cursor: pointer;
      .text {
        padding-top: 29px;
      }
      img {
        width: 196px;
        height: 49px;
      }
    }
  }
  .footer-concat {
    width: 100%;
    height: auto;
    padding: 76px 0 178px;
    .footer-concat-item {
      width: 50%;
      height: auto;
      color: #fff;
      float: left;
      padding: 0 195px;
      box-sizing: border-box;
      .footer-concat-icon {
        width: 33px;
        height: 33px;
        float: left;
        .iconfont {
          font-size: 33px;
        }
      }
      .footer-concat-text {
        width: auto;
        margin-left: 44px;
        .footer-concat-type {
          width: 100%;
          line-height: 16px;
          font-size: 16px;
          margin-bottom: 9px;
        }
        .footer-concat-msg {
          width: 100%;
          line-height: 18px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .footer-copyright {
    width: 100%;
    line-height: 83px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    border-top: 1px solid #fff;
  }
}
</style>
